<template>
  <v-container fluid class="d-flex justify-center align-center">
      <v-slide-group multiple show-arrows :center-active="true" class="d-none d-md-flex">
        <v-slide-item v-for="(affiliate,index) in affiliates" :key="index" class="text-decoration-none">
          <div class="d-flex flex-column justity-center align-center affiliate_card">
            <v-img :src="affiliate.img" color="grey" contain height="150" width="170px" />
            <button class="text-button" @click.prevent="openDialog(affiliate)">
              {{affiliate.text}}
              <img src="@/assets/affiliate_button.svg" alt="Chevron"/>
            </button>
          </div>
        </v-slide-item>
      </v-slide-group>

      <v-carousel cycle height="200" hide-delimiters class="d-block d-md-none" :show-arrows="true" >
        <v-carousel-item v-for="(affiliate,index) in affiliates" :key="index" >
            <div class="d-flex py-0 flex-column justity-center align-center">
              <v-img :src="affiliate.img" color="grey" contain height="130" width="50%"/>
              <button class="text-button" @click.prevent="openDialog(affiliate)">
                {{affiliate.text}}
              <img src="@/assets/affiliate_button.svg" alt="Chevron"/>
              </button>
            </div>
        </v-carousel-item>
      </v-carousel>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline blue py-2 white--text text-uppercase">{{affiliate.text}}</v-card-title>
        <div class="pa-5">
          <p>
            <strong>Presidente:</strong>
            {{affiliate.president}}
          </p>
          <p>
            <strong>Endereço:</strong>
            {{affiliate.address}}
          </p>
          <p>
            <strong>Email:</strong>
            {{affiliate.email}}
          </p>
          <p>
            <strong>Telefone:</strong>
            {{affiliate.phone}}
          </p>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Sair</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "AffiliatesHome",
  methods: {
    openDialog(affiliate) {
      if (!affiliate.url) {
        this.affiliate = {
          address: affiliate.address,
          email: affiliate.email,
          president: affiliate.president,
          phone: affiliate.phone,
          text: affiliate.text
        };
        this.dialog = true;
      } else {
        window.location.href = affiliate.url;
      }
    }
  },
  data() {
    return {
      dialog: false,
      affiliate: {},
      affiliates: [
        {
          img: require("../../../assets/logoseebcaratinga.svg"),
          url: " http://bancarioscaratinga.org.br/plus/",
          text: "Seeb caratinga"
        },
        {
          img: require("../../../assets/logofeebmg.svg"),
          url: " http://http://feebmg.org.br/",
          text: "Feeb de MG GO TO DF"
        },
        {
          img: require("../../../assets/logoseebcontec.svg"),
          url: "https://contec.org.br/",
          text: "Contec"
        },
        {
          img: require("../../../assets/logoseebcurvelo.svg"),
          url: "https://www.seebcurvelo.com/",
          text: "Seeb curvelo"
        },
        {
          img: require("../../../assets/logoseebsintrafgv.svg"),
          url: "https://www.sintrafgv.com.br/",
          text: "Seeb sintra fgv"
        },
        {
          img: require("../../../assets/logoseebitajuba.png"),
          url: "http://bancariositajuba.org.br/plus/",
          text: "Seeb itajuba"
        },
        {
          img: require("../../../assets/logoseebituiutaba.png"),
          url: "https://www.bancariosituiutaba.org.br/",
          text: "Seeb ituiutaba"
        },
        {
          img: require("../../../assets/logoseebmontesclaros.png"),
          url: "http://bancariosmoc.org.br/plus/",
          text: "Seeb montes claros"
        },
        {
          img: require("../../../assets/logooseebmuriae.png"),
          url: "http://bancariosmuriae.org.br/plus/",
          text: "Seeb muriae"
        },
        {
          img: require("../../../assets/logoseebpocosdecaldas.png"),
          url: "http://www.sintrafpcr.org.br/",
          text: "Seeb pocos de caldas"
        },
        {
          img: require("../../../assets/logoseebvarginha.png"),
          url: "https://www.bancariosvarginha.org.br/",
          text: "Seeb varginha"
        },
        {
          img: require("../../../assets/logoseebpontenova.png"),
          url: "http://bancariospnr.org.br/",
          text: "Seeb ponte nova"
        },
        {
          img: require("../../../assets/logoseebbarbacena.png"),
          url: "http://www.bancariosbarbacena.org.br/plus/",
          text: "Seeb barbacena"
        },
        {
          img: require("../../../assets/logoseebsantosdumont.png"),
          url: null,
          text: "Seeb Santos Dumont",
          president: "Marcos João Couri",
          address:
            "Rua Sara Curi de Castro, nº 192 - Bairro Cabangu, 36240-000 - Santos Dumont / MG",
          phone: "(32) 3251-3764",
          email: "seebsd1963@yahoo.com.br"
        },
        {
          img: require("../../../assets/logoseebcatalao.png"),
          url: null,
          text: "Seeb Catalão",
          president: "Elciro Torquato Pereira",
          address:
            "Av. 20 de Agosto, nº 1487, Galeria Chaud - Sala 201 - Caixa Postal 151 - Centro, 75701-010 - Catalão / GO",
          phone: "(64) 3441-2964",
          email: "seebsd1963@yahoo.com.br"
        },
        {
          img: require("../../../assets/logoseebmanhuacu.png"),
          url: null,
          text: "Seeb Manhuaçu",
          president: "Maria Goretti de Oliveira Afonso Santos",
          address:
            "Rua Des. Alonso Starling, nº 346, Salas 02/03 - Centro, 36900-000 - Manhuaçu / MG",
          phone: "(33) 3331-2360",
          email: "seebmcumg@yahoo.com.br"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
   max-width: 1360px;
   padding: 60px 0px;
}
.v-slide-group {
  height: 280px;
}
.affiliate_card {
  max-width: 250px;
  height: 268px;
  margin-right: 16px;
  background-color: #F7F7F7;
  border: 1px solid #1C8BE3;
  border-radius: 8px;
  padding: 25px 31px;
  box-shadow: 0px 6.65px 5px rgba(0, 0, 0, 0.2);
}
.text-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(28, 139, 227, 0.3);
  border-radius: 6px;
  padding: 5px 10px;
  margin-top: 23px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  color: #334666;
} 

</style>
